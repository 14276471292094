import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { BillingCycleMaintenanceProvider } from "presentation/store/BillingCycle/BillingCycleMaintenanceProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { BillingCycleMasterView } from "presentation/view/section/BillingCycle/BillingCycleMasterView";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const BillingCycleMaintenanceCont = () => {
    return <ANAInfoWrapper permission={Permission.BILLING_CYCLE_MAINTENANCE}>
        <MessageBarWrapper>
            <BillingCycleMaintenanceProvider>
                <GridStyles/>
                <BillingCycleMasterView/>
            </BillingCycleMaintenanceProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default BillingCycleMaintenanceCont;