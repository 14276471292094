import { EMPTY_MASTER_BILLING_CYCLEANY_MODEL } from "presentation/model/BillingCycle/BillingCycleMaintenanceModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: BillingCycleMaintenanceProvider,
    useTracked: useBillingCycleMaintenanceTracked
} = createContainer(() => useState(EMPTY_MASTER_BILLING_CYCLEANY_MODEL), {concurrentMode: true});
export { BillingCycleMaintenanceProvider, useBillingCycleMaintenanceTracked };

