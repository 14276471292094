export const billingCycleMaintenanceRequiredFieldList: string[] = [
    'billingCycle', 
];

export const BillingCycleMaintenanceConstant = {    
    CATEGORY: "CODE",
    TITLE: "Billing Cycle",
    BILL_CYCLE: "Billing Cycle",
    BILL_CYCLE_CODE: 'Billing Cycle Code',
    FROM_DATE_RANGE: 'Billing Cycle Date Range',
    FROM_DATE: 'From Date',
    TO_DATE: 'To Date',
    ACTIVE_IND: 'Active Ind.',
}
