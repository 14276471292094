import * as yup from 'yup';


export const commonBillingCycleMaintenanceValidation: { [x: string]: any; } = {
  billingCycle: yup.string().required("Missing"),
  billingCycleCode: yup.string().required("Missing"),
  fromDate: yup.date().required("Missing"),
  toDate: yup.date().required("Missing"),
};

export const createBillingCycleMaintenanceValidation: { [x: string]: any; } = {
  ...commonBillingCycleMaintenanceValidation,
};

export const createBillingCycleMaintenanceValidationSchema = yup.object().shape(createBillingCycleMaintenanceValidation);

