
import { useBillingCycleMaintenanceVM } from "presentation/hook/BillingCycle/useBillingCycleMaintenanceVM";
import { useBillingCycleMaintenanceTracked } from "presentation/store/BillingCycle/BillingCycleMaintenanceProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import BillingCycleTablePanel from "./BillingCycleTablePanel";

const BillingCycleMaintenance:React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [billingCycleState] = useBillingCycleMaintenanceTracked();
    const billingCycleVM = useBillingCycleMaintenanceVM();
    const {isBackMaster} = billingCycleState;
    

    useEffect(() => {
        const initialScreen = async() => {   
            setIsLoading(true);
            try {     
                const results = await Promise.allSettled([
                    billingCycleVM.onSearch(),
                    billingCycleVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    } 
                })
            } catch(error) {
                setIsLoading(false);
            }
        }
        initialScreen().then(() => {
            setIsLoading(false)
        }).catch(error => {            
            setIsLoading(false)
        });
        
    }, [isBackMaster,billingCycleVM])


    
    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    
    return <>
        <div className={`main-comp-wrapper im-hide-side-form-draggable`}>
            <SliderPanel
                isOpen={false}
                draggable={false}
                leftSectionWidth={"0%"}
                rightSectionWidth={"100%"}
                leftChildren={<BillingCycleTablePanel/>}
                />
        </div>
    </>
}

export default memo(BillingCycleMaintenance);