import { BillingCycleMaintenanceConstant } from "presentation/constant/BillingCycle/BillingCycleMaintenanceConstant";
import { useBillingCycleMaintenanceVM } from "presentation/hook/BillingCycle/useBillingCycleMaintenanceVM";
import { useBillingCycleMaintenanceTracked } from "presentation/store/BillingCycle/BillingCycleMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useMemo } from "react";
import { FieldType, HPHCheckbox, IFieldValue } from "veronica-ui-component/dist/component/core";
import { SidebarCaption, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import BillingCycleEditPanelTitleBar from "./BillingCycleEditPanelTitleBar";

export const BillingCycleMaintenanceFormPanel = () => {
    const [billingCycleState] = useBillingCycleMaintenanceTracked();
    const billingCycleVM = useBillingCycleMaintenanceVM();
    const { currentSelectedRow, masterState } = billingCycleState;
    const { editingEntity, isAdd, isEditable, isRead, isSaveClicked, allFormState } = masterState;
    const SCREEN_CONSTANT = BillingCycleMaintenanceConstant;


    const currentEntity = isRead ? currentSelectedRow : editingEntity;

    const memoBillingCycle = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd}
                isShowOptional={false}
                readOnlyValue={currentEntity?.billingCycle || ''}
                fieldValue={currentEntity?.billingCycle}
                fieldLabel={SCREEN_CONSTANT.BILL_CYCLE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'billingCycle'}
                isShowMissingError={true}
                maxLength={10}
                options={billingCycleState.dynamicOptions.billingCycleTypeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billingCycleVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isAdd, currentEntity?.billingCycle, SCREEN_CONSTANT.BILL_CYCLE, isSaveClicked, billingCycleState.dynamicOptions.billingCycleTypeDropdownOptions, billingCycleVM])

    const memoBillingCycleCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd}
                isShowOptional={false}
                readOnlyValue={currentEntity?.billingCycleCode || ''}
                fieldValue={currentEntity?.billingCycleCode}
                fieldLabel={SCREEN_CONSTANT.BILL_CYCLE_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'billingCycleCode'}
                isShowMissingError={true}
                maxLength={10}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billingCycleVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isAdd, currentEntity?.billingCycleCode, SCREEN_CONSTANT.BILL_CYCLE_CODE, isSaveClicked, billingCycleVM])

    const memoFromToDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>
            <DateRangePickerComponent
                label={SCREEN_CONSTANT.FROM_DATE_RANGE}
                width='330px'
                fieldNames={{ startField: "fromDate", endField: "toDate" }}
                dateRange={{ startDate: currentEntity.fromDate, endDate: currentEntity.toDate }}
                onDatesChange={billingCycleVM.onDateRangeChange}
                disabled={!(isEditable || isAdd)}
            />
        </div>
        , [SCREEN_CONSTANT.FROM_DATE_RANGE, billingCycleVM.onDateRangeChange, currentEntity?.fromDate, currentEntity?.toDate, isAdd, isEditable])

    const memoActiveInd = useMemo(() =>
        <>
            <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>

                <NbisCommonField
                    fieldKey={"activeInd"}
                    requiredFieldList={[]}
                    fieldLabel={SCREEN_CONSTANT.ACTIVE_IND}
                    fieldType={FieldType.TEXT}
                    isReadOnly={true}
                    onFieldChange={() => { }} />
                <HPHCheckbox
                    label={""}
                    checked={currentEntity?.activeInd === 'Y'}
                    disabled={!(isEditable || isAdd)}
                    onChange={(e) => billingCycleVM.onCheckboxChange(e.checked, "activeInd")}
                />
            </div>
        </>
        , [SCREEN_CONSTANT.ACTIVE_IND, currentEntity?.activeInd, isEditable, isAdd, billingCycleVM])


    const memoEditPanelTitleBar = useMemo(() => {
        return <BillingCycleEditPanelTitleBar />
    }, [])


    return <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
        {/* Header part of add or edit record */}
        {memoEditPanelTitleBar}

        {/* Readable and Editable Form */}
        <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
            <div className="flex-row-auto">
                <div className={'flex-row-item'}>
                    <div className={'im-flex-row'}>
                        <div className={'flex-row-item-stretch'}>
                            <SidebarTitle>{!isAdd ? currentEntity.billingCycleCode : "NEW CODE"}</SidebarTitle>

                            {<SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                        </div>
                    </div>

                    <CriteriaItemContainer>
                        {memoBillingCycleCode}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoBillingCycle}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoFromToDate}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoActiveInd}
                    </CriteriaItemContainer>

                </div>
            </div>
        </div>
        {/* Readable and Editable Form */}
    </StyledSidebar>
}
