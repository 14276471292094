import { BillingCycleMaintenanceConstant } from "presentation/constant/BillingCycle/BillingCycleMaintenanceConstant";
import { useBillingCycleMaintenanceVM } from "presentation/hook/BillingCycle/useBillingCycleMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useBillingCycleMaintenanceTracked } from "presentation/store/BillingCycle/BillingCycleMaintenanceProvider";
import { memo, useCallback, useState } from "react";
import { HPHBreadcrumb, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const BillingCycleEditPanelTitleBar: React.FC = () => {
    const [billingCycleState] = useBillingCycleMaintenanceTracked();
    const billingCycleVM = useBillingCycleMaintenanceVM();
    const messageBarVM = useMessageBarVM();
    const {currentSelectedRow, masterState} = billingCycleState;
    const {editingEntity, isAdd, isEditable, isRead} = masterState;
    
    const [isLoading, setIsLoading] = useState(false);
    const currentEntity = isRead? currentSelectedRow : editingEntity;

    const handleReset = useCallback(async () => {
        billingCycleVM.onReset();
    }, [billingCycleVM]);

    const handleEdit = useCallback(()=>{
        billingCycleVM.onEditClick(currentEntity);
    },[currentEntity, billingCycleVM])

    const handleSave = useCallback(async () => {
        if(!currentEntity.billingCycle){
            messageBarVM.showError('Billing Cycle is mandatory.');
            return;
        }
        if(!currentEntity.billingCycleCode){
            messageBarVM.showError('Billing Cycle Code is mandatory.');
            return;
        }
        if(!currentEntity.fromDate){
            messageBarVM.showError('From Date is mandatory.');
            return;
        }
        if(!currentEntity.toDate){
            messageBarVM.showError('To Date is mandatory.');
            return;
        }
        setIsLoading(true);
        try {
            billingCycleVM.onSaveClicked();
            const res = await billingCycleVM.onSave(currentEntity,isAdd);
            if(!!!res || !res.success){
                messageBarVM.showWarining(res?.data??'Save data failed.');
            }else {
                billingCycleVM.onClose();
                setIsLoading(true);
            }
        } catch (error) {

            messageBarVM.showError('Save data failed.');
        }finally{
            setIsLoading(false)
        }
        
    },[currentEntity, isAdd, messageBarVM, billingCycleVM]);

    return <Sidebarheader style={{width: '100%'}}>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <Breadcrumb>
            <HPHBreadcrumb breadcrumbData={[{title: BillingCycleMaintenanceConstant.CATEGORY},
                { title: BillingCycleMaintenanceConstant.TITLE }]}onCurrentClick={()=>{}}>
            </HPHBreadcrumb>
        </Breadcrumb>
        <StyledAction>
            {!(isAdd || isEditable) && currentEntity.masterType !== 'Country Code' && 
                <IconButton fileName='Icon-pen' size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />}
            {(isAdd || isEditable) && <IconButton fileName="Icon-reset" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={handleReset} />}
            {<IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={billingCycleVM.onClose} />}
            {(isAdd || isEditable) && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />}
        </StyledAction>
    </Sidebarheader>
}

export default memo(BillingCycleEditPanelTitleBar);