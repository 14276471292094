
export interface BillingCycleEntity {
    id:number,
    billingCycle: string,
    billingCycleCode: string|null,
    fromDate: string|null,
    toDate: string|null,
    activeInd: string|null,

    [key:string]: string | number | null | undefined
}

export const EMPTY_BILLING_CYCLE_ENTITY:BillingCycleEntity = {
    id: 0,
    billingCycle: "",
    billingCycleCode: null,
    fromDate: null,
    toDate: null,
    activeInd: "Y"
}

