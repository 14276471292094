import { BillingCycleRepoImpl } from "domain/repository/BillingCycle/BillingCycleRepoImpl";
import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { useBillingCycleMaintenanceTracked } from "presentation/store/BillingCycle/BillingCycleMaintenanceProvider";
import { BillingCycleMaintenanceVM } from "presentation/viewModel/BillingCycle/BillingCycleMaintenanceVM";
import { useMemo } from "react";

export const useBillingCycleMaintenanceVM = () => {
    const [, setBillingCycleMaintenanceState] = useBillingCycleMaintenanceTracked();
    const billingCycleMainVM = useMemo(() =>
        BillingCycleMaintenanceVM({
            dispatch: [setBillingCycleMaintenanceState],
            repo: BillingCycleRepoImpl(),
            parameterDetailRepo: ParameterDetailRepoImpl(),
        }), [setBillingCycleMaintenanceState])

    return billingCycleMainVM
}

