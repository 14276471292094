
import { useBillingCycleMaintenanceTracked } from "presentation/store/BillingCycle/BillingCycleMaintenanceProvider";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import BillingCycleMaintenance from "./BillingCycleMaintenance";
import { BillingCycleMaintenanceFormPanel } from "./BillingCycleMaintenanceFormPanel";

export const BillingCycleMasterView: React.FC = () => {
    const [billingCycleState] = useBillingCycleMaintenanceTracked();
    // const { isAdd } = billingCycleState.masterState;
    const { isShowEditPanel } = billingCycleState;

    return <>
        <div className="main-comp-wrapper im-hide-side-form-draggable">
            <BillingCycleMaintenance/>
            {
                (isShowEditPanel && 
                <SliderPanel
                    isOpen={false}
                    leftSectionWidth={"100%"}
                    rightSectionWidth={"0%"}
                    leftChildren={<BillingCycleMaintenanceFormPanel/>}
                />
                )
            }
        </div>
    </>
}