import { BillingCycleEntity } from "domain/entity/BillingCycle/BillingCycleEntity";
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { BillingCycleRepository } from "./BillingCycleRepo";

/**
 * For template
 * @returns 
 */
export const BillingCycleRepoImpl = (): BillingCycleRepository => {
    const url = '/v1/billingCycle';

    const getEntities = async() : Promise<BillingCycleEntity[]> => {
        return await axiosGetData(referenceAxiosInstance, url, []).then(res => {
            return res.data
        }).catch(err => {
            return [];
        });
        
    }

    const getEntityByKey = async(key: string): Promise<BillingCycleEntity> => {
        return await axiosGetData(referenceAxiosInstance, `${url}/${key}`, []).then(res => {
            return res.data;
        }).catch(err => {
            return null;
        });
    }

    const createEntity = async(newData: BillingCycleEntity) : Promise<ResponseEntity> => {
        return await axiosPostData(referenceAxiosInstance, `${url}`, newData);
    }

    const updateEntity = async(updatedData: BillingCycleEntity): Promise<ResponseEntity> => {
        return await axiosPutData(referenceAxiosInstance, `${url}`, updatedData);
    }

    const deleteEntity= async(key: string): Promise<ResponseEntity> => {
        return await axiosDeleteData(referenceAxiosInstance, `${url}`, key);
    }

    return {
        getEntities: getEntities,
        getEntityByKey: getEntityByKey,
        createEntity: createEntity,
        updateEntity: updateEntity,
        deleteEntity: deleteEntity,
    }
}